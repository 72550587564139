<template>
  <div class="view" v-loading="isLoading">
    <CompanyName />
    <div class="mt-4">
      <div class="mt-6 p-3 text-center grey-color">{{ $t('Search Barcode ID') }}</div>
      <div>
        <el-radio-group v-model="searchType" class="w-full">
          <div class="mt-4">
            <div>
              <el-radio label="tracking-number">
                <span class="text-sm">{{ $t('Tracking Number') }}</span>
              </el-radio>
            </div>
            <div>
              <BaseInput
                :key="trackingNumberKey"
                :fieldName="trackingNumberKey"
                v-model="trackingNumber"
                v-bind="fieldSchema"
                :disabled="searchType !== 'tracking-number'"
              />
            </div>
          </div>

          <div class="mt-4">
            <div>
              <el-radio label="external-id" class="text-sm">
                <span class="text-sm">{{ $t('externalId') }}</span>
              </el-radio>
            </div>
            <div>
              <BaseInput
                :key="trackingNumberKey"
                :fieldName="trackingNumberKey"
                v-model="externalId"
                v-bind="fieldSchema"
                :disabled="searchType !== 'external-id'"
              />
            </div>
          </div>
        </el-radio-group>
      </div>
      <hr />
      <div class="flex flex-col items-center border mt-10">
        <button
          :disabled="isDisableSearchButton"
          @click="searchBarcode"
          class="cursor-pointer flex flex-row justify-evenly items-center search-btn"
        >
          <IconSearch />
          <span class="btn-search-text">{{ $t('Search Barcode ID') }}</span>
        </button>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import BaseInput from '@/components/BaseInput.vue'
import CompanyName from '@/components/CompanyName.vue'
import IconSearch from '@/components/svg/IconSearch.vue'
import { SAVE_BARCODE_SEARCH_PARAMS } from '@/store/actions'
import errorHandler from '@/utils/errorHandler'
import { EBCRType, ECustomFieldType, EOCRType } from 'smartbarcode-web-core/src/utils/enums/index'
import { IBarcodeSearchForm, TError } from 'smartbarcode-web-core/src/utils/types/index'
import { Options, Vue } from 'vue-class-component'
@Options({
  components: { IconSearch, BaseInput, CompanyName },
  name: 'BarcodeSearchPage',
})
export default class BarcodeSearchPage extends Vue {
  searchType = 'tracking-number'
  trackingNumberKey = 'TPNumber'
  trackingNumber = ''
  externalId = ''
  isLoading = false

  get projectDetail() {
    return this.$store.state.project.details
  }

  get fieldSchema() {
    return {
      fieldType: ECustomFieldType.TEXT,
      ocrReaderType: EOCRType.DEFAULT,
      barcodeReaderType: EBCRType.UNKNOWN,
    }
  }

  get isDisableSearchButton() {
    return (
      (this.searchType === 'external-id' && !this.externalId) ||
      (this.searchType === 'tracking-number' && !this.trackingNumber)
    )
  }

  // searchConditions: [{ key: 'projectId', valueType: 'string', value: this.projectDetail.id }],
  async searchBarcode() {
    try {
      const countPerPage = Math.round((window.innerHeight - 130) / 100)
      const searchForm: IBarcodeSearchForm = {
        projectCode: this.projectDetail?.code,
        version: this.$store.getters.projectVersion,
        searchConditionBlocks: [
          {
            searchConditions: [{ key: 'projectId', valueType: 'string', value: this.projectDetail.id }],
            logicalOperator: 'and',
          },
        ],
        logicalOperator: 'and',
        count: countPerPage,
        skip: 0,
        sortOrder: -1,
        sortKey: 'audit.createdDateTime',
      }

      if (this.searchType === 'tracking-number') {
        const trackingNumberSearchItem = {
          key: 'activationData.trackingNumber',
          valueType: 'string',
          value: this.trackingNumber,
        }
        if (searchForm.searchConditionBlocks[0].searchConditions) {
          searchForm.searchConditionBlocks[0].searchConditions.push(trackingNumberSearchItem)
        }
      }

      if (this.searchType === 'external-id') {
        const externalNumberSearchItem = {
          key: 'activationData.externalId',
          valueType: 'string',
          value: this.externalId,
        }
        if (searchForm.searchConditionBlocks[0].searchConditions) {
          searchForm.searchConditionBlocks[0].searchConditions.push(externalNumberSearchItem)
        }
      }

      this.isLoading = true
      const condition = { condition: searchForm }

      this.$store.commit(SAVE_BARCODE_SEARCH_PARAMS, condition)

      this.$router.push({
        name: 'barcodeListPage',
        params: { searchCondition: JSON.stringify(condition) },
        query: this.flatternConditions(searchForm),
      })
    } catch (e) {
      errorHandler(e as TError)
    }
  }

  flatternConditions({ searchConditionBlocks, ...others }: IBarcodeSearchForm) {
    const conditionKeys: Record<string, string> = (searchConditionBlocks[0].searchConditions ?? []).reduce(
      (acc, item, idx) => ({
        ...acc,
        [`key_${idx}`]: item.key,
        [`valueType_${idx}`]: item.valueType,
        [`value_${idx}`]: item.value,
      }),
      {}
    )

    return { ...others, ...conditionKeys }
  }
}
</script>
<style lang="scss" scoped>
@import '~@/assets/css/mixins.scss';

.grey-color {
  background: $light-grey;
}

:deep() {
  .label-round {
    font-size: medium;
  }

  .el-radio {
    display: flex;
    justify-items: center;

    &__input {
      margin-top: 7px;
      display: flex;
      justify-items: center;
    }
  }
}

.search-btn {
  width: 220px;
  height: 60px;
  color: #f2f2f2;
  background: #2f80ed;
  border: 1px solid #bdbdbd;
  border-radius: 10px;
  font-size: 15px;
  font-weight: 500;
}
</style>
